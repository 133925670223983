@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* For Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
    height: 8px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
    background: rgba(2, 2, 3, 1); /* Color of the scrollbar track */
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #58585e; /* Color of the scrollbar handle */
    border-radius: 4px; /* Radius for the rounded scrollbar handle corners */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
}

/* Fix setting Highcharts width - otherwise it's set to 600px */

.highcharts-container {
    width: 100% !important;
    height: 100% !important;
}

.highcharts-root {
    width: 100% !important;
    height: 100% !important;
    overflow: visible !important;
}

/* Dark style controls for MapBox */
.mapboxgl-canvas.mapboxgl-canvas {
    border-radius: 0.75rem;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group {
    background: transparent;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group.mapboxgl-ctrl-group {
    border-radius: 0;
    margin: 0;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group button {
    margin: 0 0 0.25rem;
    padding: 8px;
    width: 40px;
    height: 40px;
}

.mapboxgl-ctrl-bottom-left {
    background: #1e1f1e;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0.5rem 0 0 0.5rem;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group button:not(:disabled):hover {
    background-color: #1e1f1e;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group button + button {
    border-top: none;
}

/* Updated icons for MapBox dark style */
.mapboxgl-ctrl-group.mapboxgl-ctrl-group button:hover .mapboxgl-ctrl-icon {
    opacity: 70%;
}

/* hide MapBox logo */
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl:last-child {
    display: none !important;
}

/* Dark style popups for MapBox */
.mapboxgl-popup.mapboxgl-popup .mapboxgl-popup-tip {
    border-color: transparent;
}

.mapboxgl-popup.mapboxgl-popup .mapboxgl-popup-content {
    background: transparent;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
}

/* Date picker fixes */
.react-datepicker-wrapper.react-datepicker-wrapper {
    width: 100%;
}

/* For Firefox */
/*scrollbar-color: #888 #f1f1f1; !* thumb and track color *!*/
/*scrollbar-width: thin; !* set to 'thin' or use a specific pixel value *!*/
:root {
    --color-gray-3: rgba(148, 163, 179, 1);
    --color-gray-6: rgba(88, 88, 94, 1);
    --color-gray-12: rgba(2, 2, 3, 1);

    --page-width-2xl: 1650px;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

@layer components {
    .gutter-stable {
        scrollbar-gutter: stable;
    }
}

@keyframes fadeIn{
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease 1s forwards;
}
